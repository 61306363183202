/**
 * Created by Tom on 27.02.2018
 */
import xhr from 'xhr';
import each from 'foreach';
import selectAll from './select-all';

/* const steps = [
  'start',
  'dates',
  'times',
  'forms'
]*/
const data = {
  'modal': null,
  'html': '',
  'modalBody': '',
  'modalId': 'buchungs-modal',
  'leistung': '',
  'date': '',
  'user': '',
  'time': '',
  'vorname': '',
  'nachname': '',
  'telefon': '',
  'email': '',
  'lastStep': ''
};

// const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
// const daysFull = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']

function openModal () {
  data.modal = document.getElementById(data.modalId);
  data.modal.classList.add('open');
  data.modal.innerHTML = data.html;
  document.body.classList.add('stop-scrolling');
  let closeButton = selectAll('.close');
  let leistung = selectAll('[data-leistung]');
  if (leistung !== null) {
    for (var i = leistung.length - 1; i >= 0; i--) {
      leistung[i].removeEventListener('click', getTimes);
      leistung[i].addEventListener('click', getTimes);
    }
  }
  if (closeButton !== null) {
    for (var z = closeButton.length - 1; z >= 0; z--) {
      closeButton[z].removeEventListener('click', function () {
      });
      closeButton[z].addEventListener('click', function () {
        data.modal.classList.remove('open');
        document.body.classList.remove('stop-scrolling');
      }, false);
    }
  }

  document.getElementById('modal--back').addEventListener('click', changeStep);
}

function getDates () {
  if (typeof this !== 'undefined') {
    data.leistung = this.getAttribute('data-id');
  }

  xhr({
    body: JSON.stringify({
      'modus': 'getDates',
      'leistung': data.leistung
    }),
    uri: 'ajax.php',
    method: 'POST',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, (err, res, body) => {
    if (err) {
      console.log(err);
      return false;
    }
    data.user = body.user.id;
    data.lastStep = 'start';

    var date = new Date();
    date.setDate(date.getDate() + 1);
    document.getElementById('buchung-body').innerHTML = body.html;

    var whitelist = [];
    // for (var dayx in body.outDays) {
    each(body.outDays, function (value, key, object) {
      whitelist.push({
        start: new Date(value.y, value.m, value.d),
        end: new Date(value.y, value.m, value.d)
      });
    });
    var datepick = tail.DateTime('#date-buchung', { // eslint-disable-line
      classNames: 'overlay-picker',
      locale: 'de',
      timeFormat: false,
      weekStart: 1,
      dateBlacklist: false,
      dateFormat: 'D, dd.mm.YYYY',
      dateStart: date.getTime(),
      dateRanges: whitelist
      // position: '#date-buchung-container',
    });
    /* [
      // Will Disable 05-JAN -> 07-JAN completely
      {
        start: new Date(2019, 2, 22),
        end: new Date(2019, 2, 22)
        /!* days: true *!/                // This is the default
      },

      // Will Disable Each Sunday and Saturday in FEB
      {
        start: new Date(2019, 2, 28),
        end: new Date(2019, 2, 28)
      },
      {
        // days: body.disabeldDays
      }
    ]*/
    datepick.on('change', () => {
      var date = datepick.fetchDate();
      getTimes(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
    });

    document.getElementById('datesmore').addEventListener('click', function () {
      this.classList.toggle('is--hidden');
      document.getElementById('datescontainer').classList.toggle('dates--open');
    });

    let datesElem = selectAll('.dates__elem');
    if (datesElem !== null) {
      for (var i = datesElem.length - 1; i >= 0; i--) {
        datesElem[i].addEventListener('click', function () {
          var dateTmp = new Date(Date.parse(this.getAttribute('data-time')));
          datepick.selectDate(dateTmp.getFullYear(), dateTmp.getMonth(), dateTmp.getDate(), 0, 0, 0);
          // getTimes(this.getAttribute('data-time'))
        });
      }
    }
  });
}

function getTimes () {
  if (typeof this !== 'undefined') {
    data.leistung = this.getAttribute('data-id');
  }
  xhr({
    body: JSON.stringify({
      'modus': 'getTimes',
      'leistung': data.leistung,
      'date': data.date,
      'userID': data.user
    }),
    uri: 'ajax.php',
    method: 'POST',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, (err, res, body) => {
    if (err) {
      console.log(err);
      return false;
    }
    data.lastStep = 'dates';
    // document.getElementById('modalinner').innerHTML = '';
    // document.getElementById('modalinner').innerHTML = body.html;
    document.getElementById('buchung-body').innerHTML = body.html;
    data.modalBody = document.getElementById('buchung-body').innerHTML;
    let timesElem = selectAll('.times__elem');
    if (timesElem !== null) {
      for (var i = timesElem.length - 1; i >= 0; i--) {
        timesElem[i].addEventListener('click', function () {
          getForm(this.getAttribute('data-time'));
        });
      }
    }
  });
}

function getForm (time) {
  data.time = time;
  xhr({
    body: JSON.stringify({
      'modus': 'getForm',
      'leistung': data.leistung,
      'date': data.date,
      'userID': data.user,
      'time': data.time
    }),
    uri: 'ajax.php',
    method: 'POST',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, (err, res, body) => {
    if (err) {
      console.log(err);
      return false;
    }
    data.lastStep = 'times';
    document.getElementById('buchung-body').classList.add('body--white');
    document.getElementById('buchung-body').innerHTML = body.html;

    document.getElementById('b_send').addEventListener('click', sendForm);
  });
}

function sendForm (e) {
  e.preventDefault();
  var form = document.getElementById('buchungform');

  const _vorname = document.getElementById('b-vorname');
  const _nachname = document.getElementById('b-nachname');
  const _telefon = document.getElementById('b_tel');
  const _email = document.getElementById('b_mail');
  const _anmerkung = document.getElementById('b_anmerkung');
  const _datenschutz = document.getElementById('b-daten');
  const _message = document.getElementById('b_message');
  const _spinner = document.getElementById('button-spinner');
  const _button = document.getElementById('b_send');
  // const _budget = form.elements.b_budget;


  _button.setAttribute('disabled', 'disabled');
  _spinner.classList.remove('spinner--hidden');
  _message.classList.remove('show', 'error', 'success');
  _nachname.classList.remove('is--error');
  _telefon.classList.remove('is--error');
  _email.classList.remove('is--error');
  _anmerkung.classList.remove('is--error');
  if (_datenschutz.checked === false) {
    _message.innerHTML = 'Bitte bestätigen Sie unsere Datenschutzbestimmungen';
    _message.classList.add('show', 'error');
    _button.removeAttribute('disabled');
    _spinner.classList.add('spinner--hidden');
    return false;
  }
  data.vorname = _vorname.value; // ?
  data.nachname = _nachname.value; // ?
  data.telefon = _telefon.value; // ?
  data.email = _email.value; // ?
  data.anmerkung = _anmerkung.value; // ?
  // data.budget = _budget.value; // ?


  if (data.nachname === '' || data.email === '' || data.telefon === '') {
    _nachname.classList.add('is--error');
    _telefon.classList.add('is--error');
    _email.classList.add('is--error');
    _anmerkung.classList.add('is--error');
    _message.innerHTML = 'Bitte füllen Sie die Rot Makierten Felder aus';
    _message.classList.add('show', 'error');
    _button.removeAttribute('disabled');
    _spinner.classList.add('spinner--hidden');
    return false;
  }

  // console.log(_budget.value);

  xhr({
    body: JSON.stringify({
      'modus': 'sendForm',
      'leistung': data.leistung,
      'date': data.date,
      'userID': data.user,
      'time': data.time,
      'vorname': _vorname.value,
      'nachname': _nachname.value,
      'telefon': _telefon.value,
      'email': _email.value,
      // 'budget': _budget.value,
      'anmerkung': _anmerkung.value
    }),
    uri: 'ajax.php',
    method: 'POST',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, (err, res, body) => {
    if (err) {
      console.log(err);
      return false;
    }
    if (body.error === true) {
      _message.innerHTML = body.message;
      _message.classList.add('show', 'error');
    } else {
      _message.innerHTML = body.message;
      _message.classList.add('show', 'success');
      _vorname.value = '';
      _nachname.value = '';
      _telefon.value = '';
      _email.value = '';
      _anmerkung.value = '';
      _datenschutz.checked = false;
    }
    _button.removeAttribute('disabled');
    _spinner.classList.add('spinner--hidden');
  });
  return false;
}

function open () {
  xhr({
    body: JSON.stringify({
      'modus': 'openBuchung',
      'userID': data.user
    }),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, (err, res, body) => {
    if (err) {
      console.log(err);
      return false;
    }
    var bodyParse = JSON.parse(body);
    data.html = bodyParse.html;
    openModal();
  });
}

function changeStep () {
  switch (data.lastStep) {
    case 'start':
      data.modal.innerHTML = data.html;
      document.body.classList.add('stop-scrolling');
      let closeButton = selectAll('.close');
      let leistung = selectAll('[data-leistung]');
      if (leistung !== null) {
        for (var i = leistung.length - 1; i >= 0; i--) {
          leistung[i].removeEventListener('click', getDates);
          leistung[i].addEventListener('click', getDates);
        }
      }
      if (closeButton !== null) {
        for (var z = closeButton.length - 1; z >= 0; z--) {
          closeButton[z].removeEventListener('click', function () {
          });
          closeButton[z].addEventListener('click', function () {
            data.modal.classList.remove('open');
            document.body.classList.remove('stop-scrolling');
          }, false);
        }
      }
      break;
    case 'dates':
      getDates();
      break;
    case 'times':
      document.getElementById('buchung-body').classList.remove('body--white');
      document.getElementById('buchung-body').innerHTML = data.modalBody;
      getTimes(data.date);
      break;

  }
}

function stornoOpen () {
  data.modal = document.getElementById('storno-modal');
  data.modal.classList.add('open');
  document.body.classList.add('stop-scrolling');
  let closeButton = selectAll('.close');
  if (closeButton !== null) {
    for (var z = closeButton.length - 1; z >= 0; z--) {
      closeButton[z].removeEventListener('click', function () {
      });
      closeButton[z].addEventListener('click', function () {
        data.modal.classList.remove('open');
        document.body.classList.remove('stop-scrolling');
      }, false);
    }
  }

  document.getElementById('start-strono').addEventListener('click', function () {
    let hash = this.getAttribute('data-hash');
    const _message = document.getElementById('st_message');
    const _button = document.getElementById('start-strono');
    const _spinner = document.getElementById('strono-spinner');

    _button.setAttribute('disabled', 'disabled');
    _spinner.classList.remove('spinner--hidden');
    _message.classList.remove('show', 'error', 'success');
    xhr({
      body: JSON.stringify({
        'modus': 'stornoEvent',
        'ehash': hash
      }),
      uri: 'ajax.php',
      method: 'POST',
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }, (err, res, body) => {
      if (err) {
        console.log(err);
        _message.innerHTML = 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut';
        _message.classList.add('show', 'error');
        _button.removeAttribute('disabled');
        _spinner.classList.add('spinner--hidden');
        return false;
      }
      if (body.error === true) {
        _message.innerHTML = body.message;
        _message.classList.add('show', 'error');
      } else {
        _message.innerHTML = body.message;
        _message.classList.add('show', 'success');
      }
      _button.removeAttribute('disabled');
      _spinner.classList.add('spinner--hidden');
    });
  });
}

function initInline (_this) {
  var user = _this.getAttribute('data-user');

  xhr({
    body: JSON.stringify({
      'modus': 'getLeistungen',
      'userID': user
    }),
    uri: 'ajax.php',
    method: 'POST',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, (err, res, body) => {
    if (err) {
      console.log(err);
      return false;
    }

    data.user = body.user.id;
    // data.lastStep = 'start';

    var date = new Date();
    date.setDate(date.getDate() + 1);
    // document.getElementById('buchung-body').innerHTML = body.html;

    var whitelist = [];
    // for (var dayx in body.outDays) {
    each(body.outDays, function (value, key, object) {
      whitelist.push({
        start: new Date(value.y, value.m, value.d),
        end: new Date(value.y, value.m, value.d)
      });
    });
    var datepick = tail.DateTime('#datetime-1', { // eslint-disable-line
      classNames: 'inline-picker',
      locale: 'de',
      timeFormat: false,
      weekStart: 1,
      dateBlacklist: false,
      dateFormat: 'D, dd.mm.YYYY',
      dateStart: date.getTime(),
      dateRanges: whitelist,
      startOpen: true,                   // Directly opens the Calendar Popup
      stayOpen: true,                     // Keeps the Calendar Popup Open
      position: '.buchung__inline'
    });
    datepick.on('change', () => {
      var date = datepick.fetchDate();
      data.date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      open();
      // getTimes(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
    });
  });
}

export default function initBuchung () {
  let buttons = selectAll('[data-buchung]');

  if (buttons !== null) {
    for (var i = buttons.length - 1; i >= 0; i--) {

      if (buttons[i].getAttribute('data-buchung') === 'inline') {
        initInline(buttons[i]);
      } else {
        buttons[i].addEventListener('click', open);
      }
    }
  }

  let storno = document.getElementById('storno-modal');
  if (storno) {
    stornoOpen()
  }
}
